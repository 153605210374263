
import { Link, useNavigate } from 'react-router-dom';   
import LinkWithScroll from './linkWithScroll';

const Footer = () => {
  return (
  <>
    <footer>
        <div className="footerSection">
            <div className="leftSection">
                <h3>About Cook it</h3>
                <ul>
                     <li><LinkWithScroll to="/home" anchorId="hwwrks">How it works</LinkWithScroll></li>
                    <li><Link to={'/boxes'}>The boxes</Link></li>
                     <li><Link to={'/themeals'}>The meals</Link></li>
                    {/*<li>Our desserts</li> */}
                    <li>Privacy policy</li>
                    <li>About us</li>
                </ul>
            </div>
            <div className="rightSection">
                <h3><Link to={'/contact'}>Contact us</Link></h3>
                <ul>
                    <li><a href="mailto:cookit@cookit.me">cookit@cookit.me</a></li>
                    <li><Link to="tel:041234567">04 123 45 67</Link></li>
                </ul>
                 <div className="socialImgs" >
    <Link to="https://www.instagram.com/cookit_ae/" target="_blank">
    <img className="newSVG" type="image/svg+xml" src="/images/instagram-167-svgrepo-com.svg" alt="instagram" />
    </Link>
    <img className="newSVG" type="image/svg+xml" src="/images/tiktok-svgrepo-com.svg" alt="x" />
    <img className="newSVG" type="image/svg+xml" src="/images/facebook-176-svgrepo-com.svg" alt="facebook" /></div>
            </div>
           
            {/* <div className="newsletter">
                <p>Subscribe to our newsletter</p>

                <input placeholder="Enter your email address" type="text" />
                <button>Subscribe</button>
            </div> */}
        </div>
    </footer>

    </>
  )
}


export default Footer