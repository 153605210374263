import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './modal';
import WeeklyDateRanges from "../components/weeklydaterange";
import Loading from "./loading";




const Meals = ({formData, setFormData, inRegister, setInRegister} ) => {
   const [loading, setLoading] = useState(true);  
  const [MealsList, setMealsList] = useState([]);
  const [mealsCount, setmealsCount] = useState(parseInt(formData.mealSelections.length));
  const [keys, setKeys]= useState(JSON.parse(localStorage.getItem('imgSrcs')) || [] )
//  const [imgSrc, setImgSrc] = useState('');
//  const [storedImgSrcs, setStoredImgSrcs] = useState([]);
    // const [newImgSrc, setNewImgSrc] = useState('');
 let [open, setOpen] = useState(false)
 const [smallimgs, setSmallimgs]= useState([])

  const [smallimgs1, setSmallimgs1]= useState([])
  const [smallimgs2, setSmallimgs2]= useState([])
  const [smallimgs3, setSmallimgs3]= useState(formData.smallimgs3 || [])
  const [smallimgs4, setSmallimgs4]= useState(formData.smallimgs4 || [])
  const [index, setIndex] = useState(0); 
  const [imgtoAdd, setImgtoAdd]= useState('')
  const [imgtoRemove, setImgtoRemove]= useState('')
  const [imgAction, setImgAction] = useState('')
  const [mealSelection, setMealSelection] = useState('');
  const [datelast, setDatelast] = useState('')
  const [mealsByStartDate, setMealsByStartDate] = useState(formData.mealsByStartDate)|| {};
  const [message, setMessage] = useState('');
     const navigate = useNavigate();
     const meals = formData.mealSelections;
     
     const [nmeals, setNmeals] = useState(parseInt(formData.nmeals))
     const [noMeals, setNoMeals] = useState(parseInt(formData.nmeals))
     const preferences = formData.preferences;
     
   const [filteredMealsList, setFilteredMealsList ] = useState([])
   const [selectedFilters, setSelectedFilters] = useState([]);
const filters = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classics','guilt free','chicken','meat','gourmet', 'breakfast','clear filter']

     useEffect(() => {
       if(!inRegister.includes('2')){
            navigate('/boxes')
          }
          
           setInRegister(['0','1','2','3'])
     },[])
     
     
     

  



  useEffect(()=> {


 var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
 const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]

setDatelast(lastSunday)
  const fetchMeals = async (dateused) => {
  try {
    const response = await axios.get(`/api/auth/newMeals`, {
      params: {
        dateUsed: dateused
      }
    });

    const mealsData = response.data.mergedResults;
    const prefLength = preferences.length;
    let foundMatches = false;

    if (prefLength > 0) {
      mealsData.sort((a, b) => {
        let foundA = false;
        let foundB = false;

        for (let i = 0; i < prefLength; i++) {
          if (a.mealtype && a.mealtype.length > 0 && a.mealtype[0].includes(preferences[i])) foundA = true;
          if (b.mealtype && b.mealtype.length > 0 && b.mealtype[0].includes(preferences[i])) foundB = true;
        }

        if (foundA || foundB) {
          foundMatches = true;
        }

        return foundA ? (foundB ? 0 : -1) : (foundB ? 1 : 0);
      });
    }

    if (mealsData.length === 0 ) {
      setMealsList([]);
      setFilteredMealsList([]);
      setMessage("No matched meals");
    } else {
      setFilteredMealsList(mealsData);
      setMealsList(mealsData);
      setMessage("");
    }
  } catch (error) {
    console.log(error);
    setMessage("Error fetching meals");
  }
   setLoading(false)
};


 
fetchMeals(lastSunday)
  
  },[])

 

     useEffect(() => {
      
      const data= localStorage.getItem('userdata')
      

    }, [formData])

  const handleFilter = (selectedCategory) => {
    
    if(selectedCategory === 'clear filter'){
     document.querySelectorAll('.active').forEach((el) => el.classList.remove('active'));
      setSelectedFilters([]);
    }else{
if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);

    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
            
    }
    }
    
    }  

    useEffect(() => {
   filterItems();
   
}, [selectedFilters, filteredMealsList])
  
    const filterItems = () => {

    if (selectedFilters.length > 0) {
      
      let tempItems = selectedFilters.map((selectedCategory) => {
      
        let temp = filteredMealsList.filter((item) => item.mealtype.some(type => type.includes(selectedCategory)));

        return temp;
      });

     
      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
      
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
     
    } else {
      setMealsList(filteredMealsList);
    }
  };

const handleGetRequest = async (lastSunday,index) => {
 
 setLoading(true)
       
  setDatelast(lastSunday)

   
    setIndex(index)
    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: {
          dateUsed: lastSunday
        }
      })
       .then(res=>{
        
        if (res.data === 'no meals found') {
            setMealsList([])
           
          }else{
          const mealsData = res.data.mergedResults
          
       
          const countMatches = mealsData.filter(meal => meals.includes(meal.matchingFieldFromLinks)).length
          
          setmealsCount(countMatches)
         
          
          setMealsList(mealsData)
          setFilteredMealsList(mealsData)
          }
        })
      }catch(error){
        console.log(error)
    
    }
     setLoading(false)
  };


    useEffect(() => {
   
    //console.log(nmeals)
     const buttons = document.querySelectorAll('.addMeal');
     if(mealsCount>=nmeals){
      
      buttons.forEach((button) => {
      button.classList.add("disabled");
       
      });
    }else{
     
        buttons.forEach((button) => {
        button.classList.remove("disabled");
       
      });
    };
     setFormData((prevData) => ({
      ...prevData,
      nmeals: nmeals
    }))
    
    

    }, [mealsCount,nmeals,index,meals])


   //Reset smallimgs and set it to the corresponding array when index changes
  useEffect(() => {
   
    switch (index) {
      case 0:
        setSmallimgs(smallimgs1);  // Set smallimgs to smallimgs1 content
        break;
      case 1:
        setSmallimgs(smallimgs2);
        break;
      case 2:
        setSmallimgs(smallimgs3);
        break;
      case 3:
        setSmallimgs(smallimgs4);
        break;
      default:
        setSmallimgs([]);
        break;
    }
  }, [index]);  // Trigger when index changes

  
  


  const removeTimestamp = (url) => {
  return url.split('?')[0];  // Removes the query string from the URL
};


  // Add image to the current state's array and update smallimgs


  useEffect(() => {
    switch (index) {
      case 0:
     
        
        setFormData((prevData) => ({
          ...prevData,
          smallimgs: smallimgs1,
          smallimgs1: smallimgs1,
        }));
        
        break;
      case 1:
        setFormData((prevData) => ({
          ...prevData,
          smallimgs: smallimgs2,
          smallimgs2: smallimgs2,
        }));
        break;
      case 2:
        setFormData((prevData) => ({
          ...prevData,
          smallimgs: smallimgs3,
          smallimgs3: smallimgs3,
        }));
        break;
      case 3:
        setFormData((prevData) => ({
          ...prevData,
          smallimgs: smallimgs4,
          smallimgs4: smallimgs4,
        }));
        break;
      default:
        break;
    }
  }, [smallimgs1, smallimgs2, smallimgs3, smallimgs4, setFormData, index]); // formData updated after rendering


    const handleReload=()=>{
      if(mealsCount==nmeals){
      const buttons = document.querySelectorAll('.addMeal')
      buttons.forEach((button) => {
        button.classList.add("disabled");
      });
    }

    
  }
  const addMeals = () => {
    
    setNoMeals(prevState => prevState + 1)
    setNmeals(prevState => prevState + 1)
   
  }



  

    const reload = handleReload();

    const handleNext = () => {
      
       if(mealsCount < 3){
           
            handleOpen()
       }else{
        localStorage.setItem('userdata', JSON.stringify(formData)); 
        navigate('/signup');
       }
      
    };

    const handleOpen = () => {
     
       setOpen(true);
    };
  
    const handleSelectionAdd = (event) => {
     
          if(mealsCount!=nmeals){
           
          const buttons = document.querySelectorAll('.addMeal');
            buttons.forEach((button) => {
            button.classList.remove("disabled")
            });
          const key = event.currentTarget.closest(".mealCard").id
          // console.log(key)
          setFormData((prevData) => ({
        ...prevData,
        mealSelections: [...prevData.mealSelections, key]
          }));
          
         setFormData((prevState) => {
      const updatedMealsByStartDate = {
        ...prevState.mealsByStartDate,
        [datelast]: [...(prevState.mealsByStartDate[datelast] || []), key]  // Store meal under start date
      };
      return {
        ...prevState,
        mealsByStartDate: updatedMealsByStartDate,
      };
    });  
          
 
          setKeys((prevkeys) => [key,...prevkeys ])
         
          setmealsCount(prevState => mealsCount +1)
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
          counter.innerText =  parseInt(counter.innerText) +1
          button.classList.remove('show')
          button.classList.add('hide') 

          event.currentTarget.closest(".mealCard").classList.add("addactive")
        const imgSrc = event.currentTarget.parentNode.parentNode.parentNode.querySelector(".content img").src;
  setImgtoAdd(imgSrc);  // Update imgtoAdd with the clicked image's source

               
   
          switch (index) {
            case 0:
                 setSmallimgs1((prevData) => {
        const updatedSmallImgs1 = [...prevData, imgSrc];  // Add the image to smallimgs1
        setSmallimgs(updatedSmallImgs1);  // Immediately set smallimgs to the updated value
        return updatedSmallImgs1;  // Return the new state for smallimgs1
      });
      break;

    case 1:
      // Update smallimgs2 and smallimgs with the new image
      setSmallimgs2((prevData) => {
        const updatedSmallImgs2 = [...prevData, imgSrc];  // Add the image to smallimgs2
        setSmallimgs(updatedSmallImgs2);  // Immediately set smallimgs to the updated value
        return updatedSmallImgs2;  // Return the new state for smallimgs2
      });
      break;
            case 2:
              setSmallimgs3((prevData) => {
        const updatedSmallImgs3 = [...prevData, imgSrc];  // Add the image to smallimgs2
        setSmallimgs(updatedSmallImgs3);  // Immediately set smallimgs to the updated value
        return updatedSmallImgs3;  // Return the new state for smallimgs2
      });
              break;
            case 3:
              setSmallimgs4((prevData) => {
        const updatedSmallImgs4 = [...prevData, imgSrc];  // Add the image to smallimgs2
        setSmallimgs(updatedSmallImgs4);  // Immediately set smallimgs to the updated value
        return updatedSmallImgs4;  // Return the new state for smallimgs2
      });
              break;
            default:
              break;
          }
 
  
  
         
          // const lilist = document.querySelector(".chosenmeals")
          // const liItems = lilist.getElementsByTagName("li")
          // for(let i =0 ; i < liItems.length ; i++){
          //   const content = liItems[i].innerHTML
          //   if(content === '' && imgtoAdd !== '')
          //     {
          //       liItems[i].innerHTML = `<img src="${imgtoAdd}"/>`
          //      return
          //     }
          // }
         
        }
  }


//  useEffect(() => {
  
//   setNewImgSrc(imgSrc)
  
//  },[imgSrc])

const handleSelectionRemove = (event) => {
 
 
    const key = event.currentTarget.closest(".mealCard").id
   
    

    const sindex = keys.indexOf(key);
    if (sindex > -1) {
        keys.splice(sindex, 1);
        setKeys([...keys]);
        
    }        
   
      setFormData((prevData) => {
      let updatedMeals = Array.isArray(prevData.mealSelections) ? [...prevData.mealSelections] : [];
      if (index === null) {
        if (key.trim() !== '') {
          updatedMeals = [...updatedMeals, key];
        }
      } else {
        const mealIndex = updatedMeals.indexOf(key);
        if (mealIndex !== -1) {
          updatedMeals.splice(mealIndex, 1);
        }
      }
      return {
        ...prevData,
        mealSelections: updatedMeals
      };
    });
    if (index === null) key=('');
  
        
          setmealsCount(prevState => mealsCount - 1 );
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
         
           
          counter.innerText =  parseInt(counter.innerText) -1
           
            if ( counter.innerText  === "0") {
              button.classList.remove('hide')
              button.classList.add('show')
              event.currentTarget.parentNode.parentNode.parentNode.classList.remove("addactive");
            }
          
          const imgSrcR = event.currentTarget.parentNode.parentNode.parentNode.querySelector(".content img").src;
          setImgtoRemove(imgSrcR)
          console.log(index)  
          switch (index) {
            case 0:
            console.log('here')
         setSmallimgs1((prevData) => {
           
   
          const updatedSmallImgsR1 = prevData.filter((img) => img !== imgSrcR)
          console.log(updatedSmallImgsR1)
          setSmallimgs(updatedSmallImgsR1);  // Update smallimgs
          
          return updatedSmallImgsR1;
         
              });
              break;

            case 1:
              setSmallimgs2((prevData) => {
                
                const updatedSmallImgsR2 = prevData.filter((img) => img !== imgSrcR)
                setSmallimgs(updatedSmallImgsR2);  // Update smallimgs
                return updatedSmallImgsR2;
              });
              break;
            case 2: 
              setSmallimgs3((prevData) => {
                // Remove the image (filter out the imgtoRemove)
                const baseImgToRemove = removeTimestamp(imgtoRemove);  // Normalize the URL to remove
                // const updatedSmallImgsR3 = alreadyExists ? prevData :prevData.filter((img) => img !== baseImgToRemove);
                const updatedSmallImgsR3 = prevData.filter((img) => img !== imgSrcR)
                setSmallimgs(updatedSmallImgsR3);  // Update smallimgs
                return updatedSmallImgsR3;
              });
              break;
            case 3: 
              setSmallimgs4((prevData) => {
                // Remove the image (filter out the imgtoRemove)
                const baseImgToRemove = removeTimestamp(imgtoRemove);  // Normalize the URL to remove
                // const updatedSmallImgsR4 = alreadyExists ? prevData :prevData.filter((img) => img !== baseImgToRemove);
                const updatedSmallImgsR4 = prevData.filter((img) => img !== imgSrcR)
                setSmallimgs(updatedSmallImgsR4);  // Update smallimgs
                return updatedSmallImgsR4;
              });
              break;
            default:
              break;
          
            } 
          
        }
       
    



const getMealCount = () => {
    return formData.mealSelections.reduce((acc, mealId) => {
      acc[mealId] = (acc[mealId] || 0) + 1;
      return acc;
    }, {});
  };

 const mealCounts = getMealCount();

  const emptySlots = nmeals - smallimgs.length;

    
  const containsGourmet = (mealtype) => {
  if (Array.isArray(mealtype) && mealtype.length > 0) {
    // Flatten the mealtype array and split each element by commas, then trim whitespace
    const typesArray = mealtype.flatMap(item => item.split(',').map(subItem => subItem.trim().toLowerCase()));

    
    // Check if 'gourmet' is in the resulting array
    const result = typesArray.includes('gourmet');
    console.log("Includes 'gourmet':", result);

    return result;
  }

  // Log when mealtype does not meet the required conditions
  console.log("Invalid mealtype:", mealtype);
  return false;
};


    
    

  return (
    <>
  
 <div className="container">
    
    <h1>Meals</h1>
           <h2>Select your meals</h2>

<div className="mealsList">
    <div className="mealImages">
     
<ul className="chosenmeals">
  
  {smallimgs.map((img, index) => (
  <li className="chosenmeal" key={index} data-index={index}><img src={img} alt="image" /></li>
))}
 {Array.from({ length: emptySlots }, (_, index) => (
          <li className="chosenmeal"  key={smallimgs.length + index}></li>
        ))}



</ul>
<div className='selectedTextDiv'>
<p className='selectedText'>You have selected {mealsCount} meals</p>

{noMeals < 5 ? <p className="addmeals" onClick={addMeals}>Add meal</p>: ''}
{/* {noMeals > 3 ? <p onClick={reduceMeals}>Remove meal</p>: ''} */}
{/* <p>{noMeals}</p> */}
</div>
<button className="nextButton" onClick={handleNext}>Next</button>
      </div>
      <div className="datesContainer">
     <WeeklyDateRanges  onRequest={handleGetRequest} /> 
</div>

 <div className="filterContainer">
    <ul className="filters" >
            {filters.map((category, idx) => (
              <li className={`filterbutton ${category.replace(/ /g,'')} ${selectedFilters?.includes(category)  ? "active" : ""} ${preferences.includes(category)  ? "active" : ""} `} key={`filters-${idx}`} onClick={() => handleFilter(category)}>{category}</li>
            ))}
              
            </ul>
     </div>
       <div className="mealList">
        {message && <p>{message}</p>}
        {
    
            
            MealsList
      //       .sort((a, b) => {
      //   // Sorting logic based on 'addactive' class
      //   const aHasActive = meals.includes(a.matchingFieldFromLinks);
      //   const bHasActive = meals.includes(b.matchingFieldFromLinks);

      //   if (aHasActive && !bHasActive) {
      //     return -1; // 'a' should come first if it has 'addactive'
      //   } else if (!aHasActive && bHasActive) {
      //     return 1; // 'b' should come first if it has 'addactive'
      //   }
      //   return 0; // No change in order if both are same
      // })
            
            
            
            .map( meal => {
              return <div  key={meal._id} id={meal.matchingFieldFromLinks} className={`mealCard ${meals.includes(meal.matchingFieldFromLinks)  ? "addactive" : ""}
              ${containsGourmet(meal.mealtype) ? "gourmet" : ""}`}>
                        
                       
                        {/* <div className="mealContent"> */}
                          <div className='imgContainer'> 
                          <img src={`/images/meals/${meal.image}`} alt="image" />
                          </div>
                           <div className='contentContainer'>
                          <div className="mealDetails">
                            
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                          </div>
                              
                        {/* </div> */}

                          <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                          </div> 
                           
              
                  <div className="addRemove">
                 
                    <button  onClick={handleSelectionAdd} className={`addMeal ${formData.mealSelections.includes(meal.matchingFieldFromLinks) ? "hide" : ""}`}>Add Recipe</button>
              <p  onClick={handleSelectionAdd}>+</p>
              <p className="counter">{mealCounts[meal.matchingFieldFromLinks]}</p>
                <p  onClick={handleSelectionRemove}>-</p>
                </div>
                
                              
              </div>  
            </div>
          })

        }


      </div>
        
        </div> 

    
</div>

  <Modal isOpen={open} onClose={() => setOpen(false)} >
      
    <div className="modalTop"><span>Please check your selections</span><button className="close">X</button></div>
       
        <div className="modalBody">
           <div className='modalWarning'><p>Please Select 3 Recipes</p></div>
      
    </div>
    </Modal>
    </>
  )
}

export default Meals