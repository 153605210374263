import { useLocation,useNavigate,Link } from "react-router-dom";
import { useEffect, useState, useContext, useRef} from "react";
import axios from "axios" 
import WeeklyDateRanges from "../components/weeklydaterange";
import { AuthContext } from '../context/AuthContext';
import '../styles/meals.css'
import UserPanel from "./userPanel";
import Loading from "./loading";




const MyMeals = ()=>{
  const [loading, setLoading] = useState(true);   
  const { authState } = useContext(AuthContext);
  const location  = useLocation();
  const [MealsList, setMealsList] = useState([])
  const [keys, setKeys]= useState([])
   const [mealsCount, setmealsCount] = useState();
  const [filteredMealsList, setFilteredMealsList ] = useState([])
  const preferences =authState.user.mealPreferences;
  const meals = authState.user.noMeals
  const [values, setValues] = useState([]);
  const userId= authState.user._id
  const [mealSelections, setMealSelections] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [dateused, setDateused] = useState('')
  const [updateMessage, setUpdateMessage] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([]);
  const filters = ['family friendly','kid friendly','low carb','vegetarian','pescatarian','calorie smart','classics','guilt free','chicken','meat','gourmet','clear filter']
  const [orderData, setOrderData] = useState({ mealsByStartDate: [], totalPrices: {} });
 const [smallimgs, setSmallimgs]= useState([])

  // const [smallimgs1, setSmallimgs1]= useState([])
  // const [smallimgs2, setSmallimgs2]= useState([])
  // const [smallimgs3, setSmallimgs3]= useState(formData.smallimgs3 || [])
  // const [smallimgs4, setSmallimgs4]= useState(formData.smallimgs4 || [])

  const counts = {};
  
const navigate = useNavigate();

 useEffect(() => {
   document.title = 'My Meals'
 
     const buttons = document.querySelectorAll('.addMeal');
     if(values.length==meals){
     
      buttons.forEach((button) => {
      button.classList.add("disabled");
       
      });
    }else{
       buttons.forEach((button) => {
        button.classList.remove("disabled");
       
      });
    };
      

    }, [values.length,meals,MealsList])

     const handleReload=()=>{
      if(mealsCount==meals){
      const buttons = document.querySelectorAll('.addMeal')
      buttons.forEach((button) => {
        button.classList.add("disabled");
      });
    }

    
  }

  //  useEffect(() => {
   
  //   switch (index) {
  //     case 0:
  //       setSmallimgs(smallimgs1);  // Set smallimgs to smallimgs1 content
  //       break;
  //     case 1:
  //       setSmallimgs(smallimgs2);
  //       break;
  //     case 2:
  //       setSmallimgs(smallimgs3);
  //       break;
  //     case 3:
  //       setSmallimgs(smallimgs4);
  //       break;
  //     default:
  //       setSmallimgs([]);
  //       break;
  //   }
  // }, [index]);  // Trigger when index changes


  // switch (index) {
  //           case 0:
  //                setSmallimgs1((prevData) => {
  //       const updatedSmallImgs1 = [...prevData, imgSrc];  // Add the image to smallimgs1
  //       setSmallimgs(updatedSmallImgs1);  // Immediately set smallimgs to the updated value
  //       return updatedSmallImgs1;  // Return the new state for smallimgs1
  //     });
  //     break;

  //   case 1:
  //     // Update smallimgs2 and smallimgs with the new image
  //     setSmallimgs2((prevData) => {
  //       const updatedSmallImgs2 = [...prevData, imgSrc];  // Add the image to smallimgs2
  //       setSmallimgs(updatedSmallImgs2);  // Immediately set smallimgs to the updated value
  //       return updatedSmallImgs2;  // Return the new state for smallimgs2
  //     });
  //     break;
  //           case 2:
  //             setSmallimgs3((prevData) => {
  //       const updatedSmallImgs3 = [...prevData, imgSrc];  // Add the image to smallimgs2
  //       setSmallimgs(updatedSmallImgs3);  // Immediately set smallimgs to the updated value
  //       return updatedSmallImgs3;  // Return the new state for smallimgs2
  //     });
  //             break;
  //           case 3:
  //             setSmallimgs4((prevData) => {
  //       const updatedSmallImgs4 = [...prevData, imgSrc];  // Add the image to smallimgs2
  //       setSmallimgs(updatedSmallImgs4);  // Immediately set smallimgs to the updated value
  //       return updatedSmallImgs4;  // Return the new state for smallimgs2
  //     });
  //             break;
  //           default:
  //             break;
  //         }
 

    const reload = handleReload();
const dialogRef = useRef();
const toggleDialog = async function(id) {

  if(!dialogRef.current){
    return
  } 
  dialogRef.current.hasAttribute("open") ? dialogRef.current.classList.add("closing") : dialogRef.current.classList.remove("closing");
  dialogRef.current.hasAttribute("open") ?  document.body.classList.remove("hideOverflow") : document.body.classList.add("hideOverflow");
   dialogRef.current.hasAttribute("open") ? dialogRef.current.close()  : dialogRef.current.showModal();

   if(dialogRef.current.hasAttribute("open")){
    

  }

}
const handleClick = async(e) => {
  toggleDialog();
  setLoading(true);
   e.preventDefault();
     try {                         
      const response = await axios.put(`/api/auth/updateUserMealSelections/${userId}`, {
        mealSelections:values
      })
       .then(response=>{ 
      setUpdateMessage(response.data.message)
      
     setLoading(false)
  })
             
         }catch(error){
        console.error(`Error updating meal selections:`, error);
    
    }}
      
    


  
  useEffect(()=> {




var now = new Date();
var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
var lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
 const offset = lastSunday.getTimezoneOffset()
lastSunday = new Date(lastSunday.getTime() - (offset*60*1000))
lastSunday = lastSunday.toISOString().split('T')[0]



    const fetchMeals = async(dateused) => {
    
    try{
       const response = await axios.get(`/api/auth/newMeals`, {
        params: {
          dateUsed: dateused
        }
      })
        .then(res=>{
          const mealsData =  res.data.mergedResults
           console.log(mealsData)
          const prefLength = preferences.length
          const countMatches = values.length
          setmealsCount(countMatches)
          
        console.log(countMatches)
          setmealsCount(countMatches)
          console.log(prefLength)


             switch(prefLength) {
    case 1:
        mealsData.sort((a, b) => (a.mealtype && a.mealtype[0] && a.mealtype[0].includes(preferences[0])) ? -1 : 1);
        break;
    case 2:
        mealsData.sort((a, b) => (a.mealtype && a.mealtype[0] && (a.mealtype[0].includes(preferences[0]) || a.mealtype[0].includes(preferences[1]))) ? -1 : 1);
        break;
    case 3:
        mealsData.sort((a, b) => (a.mealtype && a.mealtype[0] && (a.mealtype[0].includes(preferences[0]) || a.mealtype[0].includes(preferences[1]) || a.mealtype[0].includes(preferences[2]))) ? -1 : 1);
        break;
    case 4:
        mealsData.sort((a, b) => (a.mealtype && a.mealtype[0] && (a.mealtype[0].includes(preferences[0]) || a.mealtype[0].includes(preferences[1]) || a.mealtype[0].includes(preferences[2]) || a.mealtype[0].includes(preferences[3]))) ? -1 : 1);
        break;
    case 5:
        mealsData.sort((a, b) => (a.mealtype && a.mealtype[0] && (a.mealtype[0].includes(preferences[0]) || a.mealtype[0].includes(preferences[1]) || a.mealtype[0].includes(preferences[2]) || a.mealtype[0].includes(preferences[3]) || a.mealtype[0].includes(preferences[4]))) ? -1 : 1);
        break;
    default:
        break;
}

               
              
          setMealsList(mealsData)
           setFilteredMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    }
  }
 
fetchMeals(lastSunday)


  axios.get('/api/auth/myorders', { 
    params: { id: userId, dateUsed: lastSunday } })
  .then(response => {
    console.log();
    setMealSelections(response.data);

    // Update the state with the meal selections
    setValues(response.data);

     if(values.length > 0) { 
    values.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; }); 
  } 
  });
 
 setLoading(false)
},[]);


  
    


  const handleGetRequest = async (lastSunday) => {
    
   setLoading(true)


    try {
      const response = await axios.get(`/api/auth/newmeals`, {
        params: {
          dateUsed: lastSunday
        }
      })
       .then(res=>{
          const mealsData = res.data.mergedResults
         
          const countMatches = mealsData.filter(meal => values.includes(meal._id)).length
         
          setmealsCount(countMatches)
         
       
          setMealsList(mealsData)
         
          setFilteredMealsList(mealsData)
        })
      }catch(error){
        console.log(error)
    
    }
    axios.get('/api/auth/myorders', { 
    params: { id: userId, dateUsed: lastSunday } })
  .then(response => {
    console.log();
    setMealSelections(response.data);

    // Update the state with the meal selections
    setValues(response.data);

     if(values.length > 0) { 
    values.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; }); 
  } 
  });
 setLoading(false)
  };





const handleSelectionAdd = (event) => {


           if(mealsCount<meals && mealsCount>meals){
          const buttons = document.querySelectorAll('.addMeal');
          buttons.forEach((button) => {
        button.classList.remove("disabled")
          });
        }
          const key = event.currentTarget.closest(".mealCard").id
      
          if(mealsCount<meals){
          values.push(key)
          
          setKeys((prevkeys) => [key,...prevkeys ])
          
          setmealsCount(prevState => mealsCount +1)
          let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
          let button = event.currentTarget.closest(".mealCard").querySelector("button")
          counter.innerText =  parseInt(counter.innerText) +1
          button.classList.remove('show')
          button.classList.add('hide') 

          event.currentTarget.closest(".mealCard").classList.add("addactive")
          
  }
}



const handleSelectionRemove = (event) => {

    const key = event.currentTarget.closest(".mealCard").id
 
    console.log('Key:', key); console.log('Values:', values);

    const index = values.indexOf(key);
 console.log('Index:', index);
    if (index > -1) {
      
      values.splice(index,1)

        keys.splice(index, 1);
        setKeys([...keys]);
        
         if (mealsCount > 0) { 
             setmealsCount(prevState => mealsCount - 1 );
            let counter = event.currentTarget.closest(".mealCard").querySelector(".counter")
            let button = event.currentTarget.closest(".mealCard").querySelector("button")
         
      
         counter.innerText =  parseInt(counter.innerText -1) 
               
            if (  counter.innerText   === "0") {
           
              button.classList.remove('hide')
               button.classList.add('show')
               event.currentTarget.parentNode.parentNode.parentNode.classList.remove("addactive");
            }
            
         }
       
    }

}

 const handleFilter = (selectedCategory) => {
    
    if(selectedCategory === 'clear filter'){
     
      setSelectedFilters([]);
    }else{
      if (selectedFilters.includes(selectedCategory)) {
            let filters = selectedFilters.filter((el) => el !== selectedCategory);
            setSelectedFilters(filters);

          } else {
            setSelectedFilters([...selectedFilters, selectedCategory]);
                  
          }
     }
    
    }  

     useEffect(() => {
   filterItems();
   
}, [selectedFilters, filteredMealsList])


      const filterItems = () => {

    if (selectedFilters.length > 0) {
      
      let tempItems = selectedFilters.map((selectedCategory) => {
      
        let temp = filteredMealsList.filter((item) => item.mealtype.some(type => type.includes(selectedCategory)));
console.log(temp)
        return temp;
      });

     
      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
      
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
     
    } else {
      setMealsList(filteredMealsList);
    }
  };

    const getMealCount = (values) => {
     
    return values.reduce((acc, mealId) => {
      
      acc[mealId] = (acc[mealId] || 0) + 1;
      
      return acc;
    }, {});
  };

 const mealCounts = getMealCount(values);



    
    const containsGourmet = (mealtype) => {
  if (Array.isArray(mealtype) && mealtype.length > 0) {
    // Split the first element of the array by commas and trim whitespace
    const typesArray = mealtype[0].split(',').map(item => item.trim());
    // Check if 'gourmet' is in the resulting array
    return typesArray.includes('gourmet');
  }
  return false;
};



    return(
        <>
        <div className="dashboardContainer">
          
           <div className="userContainer">
                {/* <div className="userLinks">
                <UserPanel />
                </div>
        */}
       <div className="userData container ">        
 <div className="userDashboardAdmin">
            <h1>Select Meals</h1>
            <h2>Change or select your Meals</h2>
            

           
        <div className="mealsList">
    <Link to={'/dashboard'}><button className="btd">Back to dashboard</button></Link>
       {/* <div className="mealImages">
     
<ul className="chosenmeals">
  
  {smallimgs.map((img, index) => (
  <li className="chosenmeal" key={index} data-index={index}><img src={img} alt="image" /></li>
))}
 {Array.from({ length: emptySlots }, (_, index) => (
          <li className="chosenmeal"  key={smallimgs.length + index}></li>
        ))}



</ul>
<div className='selectedTextDiv'>
<p className='selectedText'>You have selected {mealsCount} meals</p>

{noMeals < 5 ? <p className="addmeals" onClick={addMeals}>Add meal</p>: ''}
{/* {noMeals > 3 ? <p onClick={reduceMeals}>Remove meal</p>: ''} */}
{/* <p>{noMeals}</p> */}
{/* </div> */}
{/* <button className="nextButton" onClick={handleNext}>Next</button> */}
      {/* </div>  */}
      <div className="datesContainer">
     <WeeklyDateRanges  onRequest={handleGetRequest} /> 
</div>

 <div className="filterContainer">
    <ul className="filters" >
            {filters.map((category, idx) => (
              <li className={`filterbutton ${category.replace(/ /g,'')} ${selectedFilters?.includes(category) ? "active" : ""} `} key={`filters-${idx}`} onClick={() => handleFilter(category)}>{category}</li>
            ))}
              
            </ul>
     </div>
       <div className="mealList">
        {loading ? <Loading /> :
        
    
            
            MealsList.map( meal => {
              return <div  key={meal._id} data-date={meal.weekstarting} id={meal._id} className={`mealCard ${values.includes(meal._id)  ? "addactive" : ""}
              ${containsGourmet(meal.mealtype) ? "gourmet" : ""}`}>
                        
                       
                        
                          <div className='imgContainer'> 
                          <img src={`/images/meals/${meal.image}`} alt="image" />
                          </div>
                           <div className='contentContainer'>
                          <div className="mealDetails">
                            
                              <h4>{meal.title}</h4>
                              <p>{meal.subtitle}</p>
                              
                          </div>
                              
                        {/* </div> */}

                          <div className="mealtypes">       
                              <ul>
                              {meal.mealtype.toString().split(',').map((type, index) => (
                                <li key={index} className={type.replace(/ /g,'')}>{type}</li>
                              ))
                              }

                              </ul>
                          </div> 
                           
              
                   <div className="addRemove">
                 
              <button  onClick={handleSelectionAdd} className={`addMeal ${values.includes(meal._id) ? "hide" : ""}`}>Add Recipe</button>
              <p  onClick={handleSelectionAdd}>+</p>
             <p className="counter">{mealCounts[meal._id] || 0}</p>
                <p  onClick={handleSelectionRemove}>-</p>
                </div> 
                
                              
              </div>  
            </div>
          })

        }

      
      </div>
      <button className="saveMeals" onClick={handleClick}>Save Meals</button>
        
        </div> 
          </div>    
       </div>
       </div>
</div>
          <dialog className="filter"  ref={dialogRef} id="PaymentRef">
  <div className="modalTop"><span>Processing meals</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
           
              {loading ? <img src="../images/icons/loading.gif" alt="loading" className="loading" /> : <p>{updateMessage}</p>}    
            </div>
          
            
            </dialog> 
      </>
 

 
    )
}

export default MyMeals