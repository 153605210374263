import React, { useState,useRef,useContext, useEffect } from 'react';
import { useNavigate,useLocation, Link  } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/register.css'
import axios from "axios" 



export const Checkout = ({formData, setFormData, inRegister, setInRegister, orderData} ) => {
 //const { createUser, login } = useContext(AuthContext);
     const navigate = useNavigate();
     
     
     const [ccNumber,setccNumber] = useState('')
  const [ccName, setccName] = useState('') 
  const [ccCVV, setccCVV] = useState('')
  const [ccExpiryDate, setCcexp] = useState('')  
  const [lfn, setLfn] = useState('')
  const [paymentDay, setPaymentDay] = useState('')
  const [ccnoerrorMessage, setccnoerrorMessage] = useState('')
  const [ccNameerrorMessage, setccNameerrorMessage] = useState('')
  const [ccCVVerrorMessage, setccCVVerrorMessage] = useState('')
  const [ccexperrorMessage, setccexperrorMessage] = useState('')
  const [paymentError,setPaymentError] = useState('')
  const [checked, setChecked] = useState(true)
  const [add1, setAdd1] = useState('')
  const [add2, setAdd2] = useState('')
  const [city, setCity] = useState('')
  const [area, setArea] = useState('')
  const [add1Message, setAdd1Message] = useState('')
  const [add2Message, setAdd2Message] = useState('')
  const [cityMessage, setCityMessage] = useState('')
  const [areaMessage, setAreaMessage] = useState('')
  const [streetMessage, setStreetMessage] = useState('')
  const [street, setStreet] = useState('')
  const PaymentRef = useRef();
  const [key, setKey] = useState(Math.random());
  const [ccdata, setCcdata] = useState({ })
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paid, setPaid] = useState(false);
  const email = formData.email
  const password = formData.password
  const location = useLocation();
  const neworderData = location.state.orderData

console.log(neworderData)
console.log(formData)
    useEffect(() => {
      if(!inRegister.includes('6')){
            navigate('/boxes')
          }
          
           setInRegister(['0'])
           
    },[])
    
   const handleChange = (e) => {
     let { name, value } = e.target;

     if(name === 'ccNumber' || name === 'ccCVV'){
      value = value.replace(/[^0-9]/g, '');
      
    }

    

    if(name === 'ccName'){
      value = value.replace(/[0-9]/g, '');
    }

 e.target.nextSibling.style.display = 'none';



     setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));

      switch(name){
      case "ccNumber":
      setccNumber(value)
     
      break;
      case "ccName":
      setccName(value)
      break;
      case "ccCVV":
      setccCVV(value)
      break;
      case "ccExpiryDate":
      setCcexp(value)
      break;
      case "add1":
      setAdd1(value)
      break;
      case "add2":
      setAdd2(value)
      break;
      case "city":
      setCity(value)
      break;
      case "area":
      setArea(value)
      break;
      case "street":
      setStreet(value)
      break;
      case "paymentDay":
      setPaymentDay(value)
      break;
      
    }
  }
  useEffect(() => {
            setLfn(ccNumber.slice(-4))
            const d = new Date();
               const day = d.getDate() + 7

               setFormData((prevData) => ({
        ...prevData,
        paymentDay: day,
        lfn: lfn
      }));
      
         },[lfn, ccNumber])



    function toggleDialog(e) {
     
      e.preventDefault();
//       if(!checked){
//        if(!add1 || !add2 || !city || !area || !street){

//         if(!add1){
//           document.querySelector('.add1').style.display = 'block';
//           setAdd1Message('Please enter address')
//        }

//         if(!add2){
//           document.querySelector('.add2').style.display = 'block';
//           setAdd2Message('Please enter address')
//         }
//         if(!city){
//           document.querySelector('.city').style.display = 'block';
//           setCityMessage('Please enter city')
//         }
//         if(!area){
//           document.querySelector('.area').style.display = 'block';
//           setAreaMessage('Please enter area')
//         }
//         if(!street){
//           document.querySelector('.street').style.display = 'block';
//           setStreetMessage('Please enter street')
//         }
//       }
//     }
   
   
//       if(!ccNumber || !ccName || !ccExpiryDate || !ccCVV){

     
//         if(!ccNumber){
//           document.querySelector('.ccno').style.display = 'block';
//           setccnoerrorMessage('Please enter card number')
//         }else{
//           setccnoerrorMessage('')
//         }
//         if(ccNumber.length<16){
//            document.querySelector('.ccno').style.display = 'block';
//           setccnoerrorMessage(`Please enter a valid card number `)
//         }else{
//           setccnoerrorMessage('')
//         }
//         if(!ccName){
//            document.querySelector('.ccna').style.display = 'block';
//           setccNameerrorMessage('Please enter the name on the card')
//         }else{
//           setccNameerrorMessage('')
        
//         }
//           if(!ccExpiryDate){
//              document.querySelector('.ccex').style.display = 'block';
//           setccexperrorMessage('Please enter card expiry')
         
//         }else{
//           setccexperrorMessage('')
        
//         }
//         if(!ccCVV){
//            document.querySelector('.ccvv').style.display = 'block';
//           setccCVVerrorMessage('Please enter card cvv')
         
//         }else{
//           setccCVVerrorMessage('')
        
//         }
//         return
//          }else{
        
//           document.querySelectorAll('.error').forEach(error => error.style.display = 'none')
//           setccnoerrorMessage('')
//           setccNameerrorMessage('')
//           setccexperrorMessage('')
//           setccCVVerrorMessage('')  
//          }
        
       
   
//           if(!PaymentRef.current){
//     return
//   }
 
//  if(PaymentRef.current.hasAttribute("open")){
//    document.body.classList.remove("hideOverflow")
//   PaymentRef.current.close();
//  }else{
//   document.body.classList.add("hideOverflow");
//   PaymentRef.current.showModal(); 
  
//  }
 
handleClick()
 
}





 const handleClick = async () => {
  console.log(checked);
  console.log(formData);

  let payload;
  if (!checked) {
    payload = {
      amount: neworderData.total,
      "items": [
        {
          "name": "Meals",
          "amount": neworderData.total,
          "description": `${formData.mealSelections.length} Meals`,
          "quantity": 1
        }
      ],
      "billing_data": {
        "addressLine1": formData.billingadd1,
        "addressLine2": formData.billingadd2,
        "first_name": formData.fname,
        "last_name": formData.lname,
        "street": formData.billingstreet,
        "area": formData.billingarea,
        "town": formData.billingcity,
        "phone_number": formData.contact,
        "country": "UAE",
        "email": formData.email
      },
      "customer": {
        "first_name": formData.fname,
        "last_name": formData.lname,
        "email": formData.email
      }
    };
  } else {
    payload = {
      amount: neworderData.total,
      "items": [
        {
          "name": "Meals",
          "amount": neworderData.total,
          "description": `${formData.mealSelections.length} Meals`,
          "quantity": 1
        }
      ],
      "billing_data": {
        "addressLine1": formData.addressLine1,
        "addressLine2": formData.addressLine2,
        "first_name": formData.fname,
        "last_name": formData.lname,
        "street": formData.street,
        "area": formData.area,
        "town": formData.town,
        "phone_number": formData.contact,
        "country": "UAE",
        "email": formData.email
      },
      "customer": {
        "first_name": formData.fname,
        "last_name": formData.lname,
        "email": formData.email
      }
    };
  }

  try {
    const payResponse = await axios.post('/api/auth/payment', payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    setFormData('');

    console.log(payResponse.data);
  } catch (error) {
    console.error(error);
    if (error.response) {
      console.error(error.response.data);
      console.error(error.response.status);
    }
  }
};

    
    const paymentFailure =() => {
      setPaid(false)
    }
    // const goToDashboard = () => {
    //   login(email, password);
    //    navigate('/dashboard');
    // }

    const handleCheck = (e) => {
      setChecked(!checked)
     
      if(checked){
        document.querySelector('.billingAddress').style.display = 'flex';
      }else{
        document.querySelector('.billingAddress').style.display = 'none';
      }
    }

     const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const cc_format = (value) => {
    const v = value.replace(/[^0-9]/gi, "").substr(0, 16);

    const parts = [];
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }
    return parts.length > 1 ? parts.join(" - ") : value;
  };


  return (
     <>
     
       {!paid ? (
        <>
        <div className="containerForm payment">
    <div className='left'>
        <div className="leftContents">
      <h1>Payment</h1>
                  <h2>Card details</h2>
        
         <img className='payIcon' src="../images/icons/creditcards.png" alt="credit card" />
        </div>
    </div>
    
    <div className='right'>
               <form>
                  <div className='paymentForm'>
                     <div className='errorContainer'>  <input type="text"id="ccName" name="ccName" placeholder="Enter Name On Card" value={ccName} onChange={handleChange} />
                     
                      <div className="error fnerror inputerror ccna">{ccNameerrorMessage}</div>
                      <i className="bi bi-person" aria-hidden="true"></i>
                    </div>
                     <div className='errorContainer'>  
                      <input type="text"  input-mask="credit-card"  id="ccNumber"  name="ccNumber" placeholder="XXXX-XXXX-XXXX-XXXX" value={cc_format(ccNumber)} onChange={handleChange} />
                     <div className="error fnerror inputerror ccno">{ccnoerrorMessage}</div>
                     <i className="bi bi-credit-card" aria-hidden="true"></i>
                     </div>
            
                      <div className='errorContainer'> <input type="text" id="ccExpiryDate" name="ccExpiryDate" placeholder="mm/yyyy" value={expriy_format(ccExpiryDate)}  onChange={handleChange} />
                        <div className="error fnerror inputerror ccex">{ccexperrorMessage}</div>
                        <i className="bi bi-calendar3" aria-hidden="true"></i>
                      </div>

                      <div className='errorContainer'> <input type="password" id="ccCVV" name="ccCVV" placeholder="Enter cvv" maxLength="3" value={ccCVV}  onChange={handleChange}/> 
                        <div className="error fnerror inputerror ccvv">{ccCVVerrorMessage}</div>
                        <i className="bi bi-credit-card-2-back" aria-hidden="true"></i>
                      </div>
                     
                      <h3>Billing address</h3>
                      <div className='checkboxAddress'><input type="checkbox" id="sameAddress" name="sameAddress" onChange={handleCheck} checked={checked} /><label htmlFor="sameAddress">Same as delivery address</label></div>
                      <div className='billingAddress'>

                     

                      
                      <div className='errorContainer'> <input type="text" id="add1" name="add1" placeholder="Enter Address Line 1"  value={add1}  onChange={handleChange}/> 
                      <div className="error fnerror inputerror add1">{add1Message}</div></div>
                      <div className='errorContainer'> <input type="text" id="add2" name="add2" placeholder="Enter Address Line 2"  value={add2}  onChange={handleChange}/> 
                      <div className="error fnerror inputerror add2">{add2Message}</div></div>
                      <div className='errorContainer'> <input type="text" id="street" name="street" placeholder="Enter Street Name"  value={street}  onChange={handleChange}/> 
                      <div className="error fnerror inputerror street">{streetMessage}</div></div>
                      <div className='errorContainer'> <input type="text" id="area" name="area" placeholder="Enter Area"  value={area}  onChange={handleChange}/> 
                      <div className="error fnerror inputerror area">{areaMessage}</div></div>
                      <div className='errorContainer'> <input type="text" id="city" name="city" placeholder="Enter City"  value={city}  onChange={handleChange}/> 
                      <div className="error fnerror inputerror city">{cityMessage}</div></div>
                       </div>
                      <button onClick={toggleDialog}>Place Order</button>
                      </div>
            </form>           
              </div></div>
                </>
         ):(
          
          (!paymentSuccess ?(
             <>
          <div className="containerForm payment">
         <div className="flex paySuccess">
          <h1>Payment Unsuccessful</h1>
          <h2>Thank you for wanting to join. unfortunately the payment has not been successful</h2>
          <div><button onClick={paymentFailure}>Please try again</button></div>
        </div>
        </div>
        </>
          ):(
          <>
          <div className="containerForm payment">
         <div className="flex paySuccess">
          <h1>Payment Successful</h1>
          <h2>Thank you for joining us. You can now view your <Link to ={'/dashboard'}>Dashboard</Link> to view your meals. Click the button below to go to your dashboard</h2>
          {/* <div><button onClick={goToDashboard}>Go to Dashboard</button></div> */}
        </div>
        </div>
        </>
        )
      )
         )}
            
    
    


   <dialog className="filter" ref={PaymentRef} id="PaymentRef">
  <div className="modalTop"><span>Processing payment</span><button className="close" onClick={toggleDialog}>X</button></div>
<div className="modalBody"> 
           <p>Processing Payment</p>
              <img src="../images/icons/loading.gif" alt="loading" className="loading" />       
                <p>{paymentError}</p>    
            </div>

             
            </dialog>
    

   </>
  )

}

export default Checkout
