import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import '../styles/meals.css';
import axios from "axios";
import WeeklyDateRanges from "./weeklydaterange";
import Loading from "./loading";

const Themeals = ({}) => {
  const [loading, setLoading] = useState(true);
  const [MealsList, setMealsList] = useState([]);
  const [filteredMealsList, setFilteredMealsList] = useState([]);
  const [selectedMealId, setSelectedMealId] = useState(null);
  const [mealsMessage, setMealsMessage] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const filters = ['family friendly', 'kid friendly', 'low carb', 'vegetarian', 'pescatarian', 'calorie smart', 'classic', 'guilt free', 'chicken', 'meat', 'gourmet', 'breakfast', 'clear filter'];

  useEffect(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
    const offset = lastSunday.getTimezoneOffset();
    lastSunday = new Date(lastSunday.getTime() - (offset * 60 * 1000));
    lastSunday = lastSunday.toISOString().split('T')[0];
    handleGetRequest(lastSunday);
  }, []);

  useEffect(() => {
    filterItems();
  }, [selectedFilters, filteredMealsList]);

  const handleFilter = (selectedCategory) => {
    if (selectedCategory === 'clear filter') {
      setSelectedFilters([]);
    } else {
      if (selectedFilters.includes(selectedCategory)) {
        const filters = selectedFilters.filter((el) => el !== selectedCategory);
        setSelectedFilters(filters);
      } else {
        setSelectedFilters([...selectedFilters, selectedCategory]);
      }
    }
  };

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      const tempItems = selectedFilters.map((selectedCategory) => {
        const temp = filteredMealsList.filter((item) => item.mealtype.some(type => type.includes(selectedCategory)));
        return temp;
      });

      const finaltempItems = tempItems.flat().reduce((acc, current) => {
        const index = acc.findIndex((item) => item._id.includes(current._id));
        if (index === -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      setMealsList(finaltempItems.flat());
    } else {
      setMealsList(filteredMealsList);
    }
  };

  const handleGetRequest = async (lastSunday) => {
    // setIsLoading(true); // Set loading state to true
    try {
      const response = await axios.get(`/api/auth/newMeals`, {
        params: {
          dateUsed: lastSunday
        }
      });
      if (response.data) {
        const mealsData = response.data.mergedResults;
        setMealsList(mealsData);
        setFilteredMealsList(mealsData);
      } else {
        setMealsList([]);
        setFilteredMealsList([]);
        setMealsMessage('There are currently no meals available. Please check back later.');
      }
    } catch (error) {
      console.log(error);
    }
    // } finally {
    //   //setIsLoading(false); // Set loading state to false after the request is complete
    // }
     setLoading(false)
  };

  return (
    <>
      <div className="container themeals">
        <h1>Our Meals</h1>
        <div className="datesContainer">
          <WeeklyDateRanges onRequest={handleGetRequest} />
        </div>
        <div className="filterContainer">
          <ul className="filters">
            {filters.map((category, idx) => (
              <li
                className={`filterbutton ${category.replace(/ /g, '')} ${selectedFilters?.includes(category) ? 'active' : ''}`}
                key={`filters-${idx}`}
                onClick={() => handleFilter(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>
        <div className="mealList">
          {MealsList && MealsList.length > 0 ? (
            MealsList.map(meal => (
              <div
                key={meal._id}
                id={meal._id}
                className="mealCard"
                onClick={() => {
                  setSelectedMealId(meal._id);
                }}
              >
                <div className="imgContainer">
                  <img src={`/images/meals/${meal.image}`} alt="image" />
                </div>
                <div className='contentContainer'>
                  <div className="mealDetails">
                    <h4>{meal.title}</h4>
                    <p>{meal.subtitle}</p>
                  </div>
                  <div className="mealtypes">
                    <ul>
                      {meal.mealtype.toString().split(',').map((type, index) => (
                        <li key={index} className={type.replace(/ /g, '')}>{type}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No meals available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Themeals;
